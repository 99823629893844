<template>

  <div class="inp">    
    <label v-if="label">{{ label }}</label>
    <input 
      :placeholder="placeholder" 
      v-model="edited_text"
      :name="name" 
      :id="id" 
      :autocomplete="autocomplete"
      @blur="blur"
      :type="type"/>      
    <div class="error" v-if="error">{{ error }}</div>      
  </div>

</template>


<script setup>


const props = defineProps({
  label: {
    type: String
  }, 
  text: {
    type: String
  }, 
  name: {
    type: String
  }, 
  pattern: {
    type: String,
    default: 'text'
  },
  id: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  autocomplete: {
    type: String,
    default: 'on'
  },
  placeholder: {
    type: String
  }, 
  error: {
    type: String
  },
  timeout: {
    type: Number,
    default: 0
  } 
});

const emits = defineEmits(['change:text', 'blur']);

const edited_text = ref(props.text);
const timeout = ref(null);
// const pattern = computed(() => {
//   if(props.pattern == 'text') return '/.*/g';
//   if(props.pattern == 'number') return '/[0-9]+/g';
//   if(props.pattern == 'float') return '/[0-9]+[.]?[0-9]*/g';
//   return props.pattern;
// });

const blur = () => {
  emits('blur', edited_text)
}


watch(edited_text, (val) => {
  if(timeout.value)
    clearTimeout(timeout.value);

  timeout.value = setTimeout(() => {
    emits('change:text', val)
  }, props.timeout);
});

</script>
   

<style scoped>

  input{
    width: 100%;
  }
  .inp{
    margin-bottom: 0.5rem;

    width: 100%;
  }
  .error{
    background-color: #fd5454;
    color: white;
    font-size: 0.8rem;

    display: block;
    padding: 0 4px;
    width: 100%;
    margin-bottom: -16px;
    height: 16px;
    font-size: 0.65rem;
    border-radius: 0 0 3px 3px;
    position: relative;
  }
  .error::before,
  .error::after{
    content: ' ';
    width: 1px;
    height: 27px;
    background: linear-gradient(0deg, rgb(255 0 0) 0%, rgb(253 84 84 / 0%) 100%);
    left: 0;
    top: -27px;
    position: absolute;
    z-index: 1;
  }
  .error::after{
    left: auto;
    right: 0;
  }
</style>
